import { image } from "@/store/model";
import { default_image } from "@/core/config/AppConfig";

export interface Partner {
  _id?: string;
  name: string;
  slug: string;
  partner_category: string;
  description: string;
  address: string;
  order: number;
  phone: string;
  hot: number;
  image: image;
  website: string;
  status: string;
  publish_by: string;
}

export const PartnerDefault: Partner = {
  name: "",
  slug: "",
  address: "",
  phone: "",
  partner_category: "",
  description: "",
  website: "",
  order: 0,
  hot: 0,
  image: default_image,
  status: "active",
  publish_by: "",
};
